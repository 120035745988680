<template>
  <div class="block-wrapper">
    <a-form @submit.stop.prevent="handleSubmit">
      <a-form-item label="Статус">
        <a-checkbox v-model="filterValue.is_active">
          Активный
        </a-checkbox>
      </a-form-item>

      <a-form-item label="Ключ">
        <a-input v-model="filterValue.name" required />
      </a-form-item>

      <a-form-item label="Имя">
        <a-input v-model="filterValue.slug" required />
      </a-form-item>

      <a-form-item label="Порядок">
        <a-input v-model.number="filterValue.sort_order" required />
      </a-form-item>

      <a-form-item label="Фильтр">
        <a-select
          v-model="filterValue.filter_id"
          placeholder="Выберите фильтр"
          required
        >
          <a-select-option
            v-for="(filter, index) in filters"
            :key="index"
            :value="filter.id"
          >
            {{ filter.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <a-button @click.prevent="$router.go(-1)">
          Назад
        </a-button>
        <a-button
          :style="{ marginLeft: '15px' }"
          type="primary"
          html-type="submit"
        >
          Сохранить
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import axios from '@/axios'
import { showNotification } from '@/utils'
import { GET_FILTER_VALUE, UPDATE_FILTER_VALUE } from '@/store/types'

export default {
  data() {
    return {
      filters: [],
      filterValue: {
        name: '',
        slug: '',
        filter_id: null,
        is_active: false,
        sort_order: 0,
      },
    }
  },

  created() {
    Promise.all([this.getFilterValue(), this.getFilters()])
  },

  methods: {
    handleSubmit() {
      if (!this.filterValue.filter_id) {
        return showNotification('Ошибка', 'Выберите фильтр', 'error')
      } else {
        this.$store.dispatch(UPDATE_FILTER_VALUE, this.filterValue)
      }
    },

    async getFilterValue() {
      try {
        this.filterValue = await this.$store.dispatch(
          GET_FILTER_VALUE,
          this.$route.params.id,
        )
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },

    async getFilters() {
      try {
        const { data } = await axios.get('/filters')

        this.filters = data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
</script>
